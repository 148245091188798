import styled from '@emotion/styled'
import React, { useEffect, useRef, useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { Column, Flex, Row } from 'src/microcomponents/flexbox-helpers'

import BackButton from 'components/back-button'
import Button, { DANGER, HYBRID } from 'components/button'

import Screen from 'components/screen/container'

import List from 'components/draggable/list'
import UploadFile from 'microcomponents/upload-file'
import Switch from 'src/microcomponents/switch'

import { func, object } from 'prop-types'
import {
  centerContainer,
  content,
  descriptionClass,
  headerImage as headerImageClass,
  infoContainer,
  item as itemClass,
  leftBar,
  main,
  overflowContainer,
  reveal,
  title,
  topBrandInfo,
  uploadBttn
} from '../edit-page-styles.js'

const PlaceholderLogo = styled.div`
  width: 60px;
  height: 60px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.5);
  color: black;
  font-size: 10px;
  text-align: center;
`

const StyledImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.5);
  object-fit: cover;
`

// Custom hook to handle textarea resizing
const useAutoResizeTextarea = (value) => {
  const textareaRef = useRef(null)

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto' // Reset the height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px` // Set it to the scroll height
    }
  }, [value]) // Re-run effect when value changes

  return textareaRef
}

const isValidUrl = (url) => {
  if (!url) return false
  const pattern = /^(?:https?:\/\/)?(?:[\w-]+\.)+[a-z]{2,}(?:\/\S*)?$/i
  return pattern.test(url)
}

const EditBrand = ({
  brandItem,
  createOrUpdateBrand,
  items,
  resetBrandItem,
  updateBrandObject,
  uploadPublicFile
}) => {
  const initialState = {
    about: '',
    header_image: '',
    logo: '',
    name: '',
    slug: '',
    website: '',
    is_ecommerce: false,
    ...brandItem
  }

  const [state, setState] = useState(initialState)

  useEffect(() => {
    window.onpopstate = resetBrandItem

    if (brandItem.id) {
      setState({
        ...state,
        ...brandItem
      })
    }
  }, [brandItem, resetBrandItem])

  const isFormValid = () => {
    return state.name.trim() !== '' && state.slug.trim() !== ''
  }

  const handleCreateOrUpdateBrand = () => {
    if (isFormValid()) {
      updateBrandObject(state)
      createOrUpdateBrand()
    } else {
      alert('Please fill in both the Name and Slug fields.')
    }
  }

  const isSaveDisabled =
    JSON.stringify(state) === JSON.stringify(initialState) || !isFormValid()

  const handleClearLogo = () => {
    setState({ ...state, logo: '' })
  }

  const handleUploadLogo = (downloadUrl) => {
    setState({ ...state, logo: downloadUrl })
  }

  const handleClearHeaderImage = () => {
    setState({ ...state, header_image: '' })
  }

  const {
    about,
    header_image: headerImage,
    is_ecommerce: isEcommerce,
    logo,
    name,
    slug,
    website
  } = state

  const textareaRef = useAutoResizeTextarea(state.about)

  return (
    <Screen>
      <Row full className={main}>
        <Column className={leftBar} componentStyle={{ position: 'absolute' }}>
          <BackButton path="/catalog/brands" />
        </Column>
        <DragDropContext>
          <Row full className={reveal}>
            <Flex full top={1}>
              <Column
                className={centerContainer}
                componentStyle={{ margin: '0 auto' }}
              >
                <Row align="center" justify="space-between">
                  <Switch
                    customLabel={'E-Commerce'}
                    enabled={isEcommerce}
                    onChange={(checked) =>
                      setState({ ...state, is_ecommerce: checked })
                    }
                    disabled={!isFormValid()}
                  />
                  <Flex>
                    <div className={uploadBttn}>
                      {isValidUrl(headerImage) ? (
                        <Button onClick={handleClearHeaderImage} type={DANGER}>
                          Clear Header
                        </Button>
                      ) : (
                        <UploadFile
                          postUpload={({ downloadUrl }) =>
                            setState({ ...state, header_image: downloadUrl })
                          }
                          uploadFile={uploadPublicFile}
                        >
                          Upload Header
                        </UploadFile>
                      )}
                    </div>
                    <div className={uploadBttn}>
                      {isValidUrl(logo) ? (
                        <Button onClick={handleClearLogo} type={DANGER}>
                          Clear Logo
                        </Button>
                      ) : (
                        <UploadFile
                          postUpload={({ downloadUrl }) =>
                            handleUploadLogo(downloadUrl)
                          }
                          uploadFile={uploadPublicFile}
                        >
                          Upload Logo
                        </UploadFile>
                      )}
                    </div>
                    <Button
                      onClick={handleCreateOrUpdateBrand}
                      type={HYBRID}
                      disabled={isSaveDisabled}
                    >
                      Save Brand
                    </Button>
                  </Flex>
                </Row>

                <Column className={content} top={1} full>
                  <div className={topBrandInfo}>
                    <Column className={infoContainer}>
                      <div
                        className={headerImageClass}
                        style={{ backgroundImage: `url(${headerImage})` }}
                      />
                      <Column componentStyle={{ zIndex: 10, padding: '1rem' }}>
                        <Row
                          full
                          componentStyle={{
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <input
                            className={title}
                            onChange={(event) =>
                              setState({ ...state, name: event.target.value })
                            }
                            value={name}
                            placeholder="Brand name"
                          />
                          {isValidUrl(logo) ? (
                            <StyledImage src={logo} />
                          ) : (
                            <PlaceholderLogo>{'No Logo'}</PlaceholderLogo>
                          )}
                        </Row>

                        <textarea
                          ref={textareaRef}
                          className={descriptionClass}
                          onChange={(event) =>
                            setState({ ...state, about: event.target.value })
                          }
                          value={about}
                          placeholder="Description" // Hide the scrollbar and prevent manual resizing
                        />

                        <Row>
                          <Flex full>
                            <input
                              className={descriptionClass}
                              onChange={(event) =>
                                setState({
                                  ...state,
                                  website: event.target.value
                                })
                              }
                              value={website}
                              placeholder="Website"
                            />
                          </Flex>

                          <Flex align="center" justify="center" full>
                            <label htmlFor="slug">www.eaze.com/brands/</label>
                            <input
                              id="slug"
                              className={descriptionClass}
                              onChange={(event) =>
                                setState({ ...state, slug: event.target.value })
                              }
                              value={slug}
                              style={{ paddingLeft: 0 }}
                              placeholder="brand-slug"
                            />
                          </Flex>
                        </Row>
                      </Column>
                    </Column>
                  </div>

                  <div className={overflowContainer}>
                    <List
                      axis="y"
                      distance={5}
                      emptyListText="Products that are associated with BRAND will show up here."
                      itemClassName={itemClass}
                      items={items.brandItems}
                      listName="brandItems"
                      narrow
                      shouldLinkOutItems
                    />
                  </div>
                </Column>
              </Column>
            </Flex>
          </Row>
        </DragDropContext>
      </Row>
    </Screen>
  )
}

EditBrand.propTypes = {
  brandItem: object.isRequired,
  createOrUpdateBrand: func.isRequired,
  items: object.isRequired,
  resetBrandItem: func.isRequired,
  updateBrandObject: func.isRequired,
  uploadPublicFile: func.isRequired
}

export default EditBrand
