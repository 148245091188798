import React, { PureComponent } from 'react'
import EazeLogo from 'microcomponents/logo'
import { dateOnly, timeOnly, transformToUTCISOString, convertTo12HourFormat } from 'src/helpers/date'
import { utcToZonedTime } from 'date-fns-tz'
import { selectDay } from 'src/helpers/delivery-fee-ranges'
import { number, object, string } from 'prop-types'

const headerContainer = {
  height: '84px',
  marginBottom: 50
}

const row = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between'
}

const firstRow = {
  height: '24px',
  marginBottom: 5,
  ...row
}

const secondRow = {
  height: '42px',
  marginBottom: 5,
  ...row
}

const thirdRow = {
  height: '60px',
  marginBottom: 5,
  ...row
}

const displayBox = {
  ...row,
  flexDirection: 'column',
  height: '60px',
  marginBottom: 5
}

const title = {
  fontSize: 24,
  fontWeight: 'bold'
}

const subtitle = {
  height: '72px',
  fontSize: 14
}

const pageNumberStyle = {
  fontSize: '24px'
}

const depotNameDislay = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
}

export default class Header extends PureComponent {
  static propTypes = {
    pageNumber: number,
    order: object,
    today: string,
    totalPages: number
  }

  render () {
    const { pageNumber, order, today, totalPages } = this.props
    let deliveryWindowString = 'ASAP'
    if (order.deliveryWindowInfo) {
      const startISO = transformToUTCISOString(
        order.deliveryWindowInfo.timeFrom
      )
      const endISO = transformToUTCISOString(order.deliveryWindowInfo.timeTo)
      // clip the date off and use today's date to know if it's DST
      let start = `${dateOnly()}T${startISO.split('T')[1]}`
      let end = `${dateOnly()}T${endISO.split('T')[1]}`
      start = utcToZonedTime(start, order.depotTimeZone).toISOString()
      end = utcToZonedTime(end, order.depotTimeZone).toISOString()
      deliveryWindowString = `${convertTo12HourFormat(timeOnly(start))} - ${convertTo12HourFormat(timeOnly(end))}`
      deliveryWindowString = `${selectDay[
        order.deliveryWindowInfo.dayOfWeek
      ].label.substring(0, 10)} ${convertTo12HourFormat(timeOnly(start))}-${convertTo12HourFormat(timeOnly(end))}`
    }

    return (
      <div style={headerContainer}>
        <div style={firstRow}>
          <EazeLogo />
          <div style={subtitle}>{today}</div>
          <div style={pageNumberStyle}>
            {pageNumber} / {totalPages}
          </div>
        </div>
        <div style={secondRow}>
          <p style={depotNameDislay}>{order.depotName}</p>
        </div>
        <div style={thirdRow}>
          <div style={displayBox}>
            <div style={title}>
              {order.id}
            </div>
            <div style={subtitle}>
              ORDER NUMBER
            </div>
          </div>
          <div style={displayBox}>
            <div style={title}>
              {deliveryWindowString}
            </div>
            <div style={subtitle}>
              DELIVERY WINDOW
            </div>
          </div>

          <div style={displayBox}>
            <div style={title}>
              {order.createdBy.firstName} {order.createdBy.lastName[0]}.
            </div>
            <div style={subtitle}>
              CUSTOMER
            </div>
          </div>
        </div>
      </div>
    )
  }
}
