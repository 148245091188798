import React, { PureComponent } from 'react'
import { array, bool, func, number, oneOfType, string } from 'prop-types'
import styled from '@emotion/styled'
import LoadingSpinner from 'components/spinner'

import OrderItem from './order-item.js'
import OrderSummaryWidget from './order-summary'
import { dateOnly } from 'helpers/date'
import DateInput from 'src/microcomponents/input-date'

export default class DriverOrders extends PureComponent {
  static propTypes = {
    isAdmin: bool,
    isLoading: bool,
    changeDriverHistoryDate: func,
    focusedDriverId: oneOfType([
      string,
      number
    ]),
    orderHistoryDrawer: bool,
    orders: array,
    rerouteOrder: func
  }

  static defaultProps = {
    orders: []
  }

  state = {
    date: dateOnly()
  }

  handleChangeDate = (event) => {
    const { changeDriverHistoryDate, focusedDriverId } = this.props
    const newDate = event.target.value
    const filterFromByCreateDate = false
    const filterToByCreateDate = false
    changeDriverHistoryDate(focusedDriverId, newDate, filterFromByCreateDate, filterToByCreateDate)
    this.setState({ date: newDate })
  }

  render () {
    const {
      focusedDriverId,
      isAdmin,
      isLoading,
      orderHistoryDrawer,
      orders,
      rerouteOrder
    } = this.props

    const hasOrders = Boolean(orders.length)
    const showLoading = isLoading && !hasOrders
    const showEmpty = !isLoading && !hasOrders
    const showOpacity = isLoading && !showLoading

    const { date } = this.state
    const maxDate = dateOnly()

    return (
      <div>
        {orderHistoryDrawer &&
          (
            <OrderHistoryTopContainer>
              <DateContainer>
                <label htmlFor='orderHistoryDate'>Date:</label>
                <DateInput
                  id='orderHistoryDate'
                  margin='0 0 0 1rem'
                  max={maxDate}
                  name='orderHistoryDate'
                  onChange={this.handleChangeDate}
                  required
                  type='date'
                  value={date}
                />
              </DateContainer>
              {!showLoading &&
                <OrderSummaryWidget loaded={hasOrders} orders={orders} />}
            </OrderHistoryTopContainer>
          )}
        {showLoading &&
          <CenterContainer>
            <LoadingSpinner />
          </CenterContainer>}
        {showEmpty &&
          <CenterContainer>No Orders</CenterContainer>}
        <Content showOpacity={showOpacity}>
          {hasOrders &&
              orders.map((order, i) => {
                return (
                  <OrderItem
                    key={i}
                    order={order}
                    isAdmin={isAdmin}
                    rerouteOrder={rerouteOrder}
                    focusedDriverId={focusedDriverId}
                  />
                )
              })}
        </Content>
      </div>
    )
  }
}

const OrderHistoryTopContainer = styled.div`
  min-height: 10rem;
`

const DateContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: 2rem;
`

const CenterContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Content = styled.div`
  width: 100%;
  opacity: ${({ showOpacity }) => showOpacity ? 0.5 : 1};
`
