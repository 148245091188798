import api from 'api'

import history from 'components/router/history'
import { receiveCatalogBrand, requestCatalogBrand } from '../loading/actions'
import { pushNotification, SUCCESS } from '../alerts/actions'
import { setCatalogBrands } from '../catalog/actions'
import { dedupeArrayByObjectKey } from 'src/utils/helpers'
import t from './actionTypes'

export function fetchBrandById (id) {
  return async (dispatch) => {
    dispatch(requestCatalogBrand())

    const { err, data } = await api.getCatalogBrandById({ id })
    dispatch(receiveCatalogBrand())

    if (err) return

    data.items = dedupeArrayByObjectKey(data.items, 'id')

    dispatch(updateBrandObject(data))
  }
}

export function setBrandWebsite (website) {
  return {
    type: t.SET_BRAND_WEBSITE,
    website
  }
}

export function setBrandItems (items) {
  return {
    type: t.SET_BRAND_ITEMS,
    items
  }
}

export function updateBrandObject (obj) {
  return {
    type: t.UPDATE_BRAND_OBJECT,
    obj
  }
}

export function resetBrandItem () {
  return {
    type: t.RESET_BRAND_ITEM
  }
}

export function createOrUpdateBrand () {
  return async (dispatch, getState) => {
    const state = getState()

    // clone item so we don't mutate UI
    const item = {
      ...state.brandItem
    }

    dispatch(requestCatalogBrand())

    item.items = item.items.map(({ id }) => ({ id }))

    if (item.id) {
      // Update Item!
      const { err } = await api.updateCatalogBrand(item)
      dispatch(receiveCatalogBrand())

      if (err) return

      // Reset brands, we'll refetch them on the next component mount
      dispatch(setCatalogBrands([]))
      dispatch(
        pushNotification(`Successfully edited ${item.name} brand`, SUCCESS)
      )

      history.push('/catalog/brands')
    } else {
      //  we don't need this, so let's not send it.
      delete item.id
      delete item.items

      // Create Item!
      const { err } = await api.createCatalogBrand(item)
      dispatch(receiveCatalogBrand())

      if (err) return

      // Reset brands, we'll refetch them on the next component mount
      dispatch(setCatalogBrands([]))
      dispatch(
        pushNotification(`Successfully created ${item.name} brand`, SUCCESS)
      )

      history.push('/catalog/brands')
    }
  }
}
