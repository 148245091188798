import t from './actionTypes'

const defaultState = {
  name: '',
  id: '',
  items: [],
  about: '',
  website: '',
  logo: '',
  header_image: '',
  is_ecommerce: false
}

const brandItem = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_BRAND_ITEMS:
      return {
        ...state,
        items: action.items
      }

    case t.UPDATE_BRAND_OBJECT:
      return {
        ...state,
        ...action.obj
      }

    case t.RESET_BRAND_ITEM:
      return defaultState

    default:
      return state
  }
}

export default brandItem
